import { ReactComponent as UserAvatarActive } from '@/assets/user/avatar-active.svg';
import { ReactComponent as UserAvatar } from '@/assets/user/avatar.svg';
import { ReactComponent as UserGrayAvatar } from '@/assets/user/avatar-gray.svg';
import { ReactComponent as WeChatIcon } from '@/assets/user/chat.svg';
import { ReactComponent as PhoneIcon } from '@/assets/user/phone.svg';
import React from 'react';

export enum UserEnum {
  Phone = 'phone',
  WeChat = 'weChat',
  Avatar = 'avatar',
}

export type UserType = 'phone' | 'weChat' | 'avatar';

export type UserIconProps = {
  type: UserType;
  active?: boolean;
  gray?: boolean;
} & React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  };
export const UserIcon: React.FC<UserIconProps> = (props) => {
  const { type, active, gray, ...rest } = props;
  switch (type) {
    case UserEnum.Phone:
      return <PhoneIcon color="#1D2129" {...rest} />;
    case UserEnum.WeChat:
      return <WeChatIcon {...rest} />;
    case UserEnum.Avatar:
      if (active) {
        return <UserAvatarActive {...rest} />;
      }
      if(gray) {
        return <UserGrayAvatar {...rest}/>
      }
      return <UserAvatar {...rest}/>
    default:
      return null;
  }
};
