export const basicColumnTitleZhCN = {
  title: '标题',
  abstract: '摘要',
  fulltext: '全文',
  publish_date: '发表日期',
  journal: '期刊',
  authors: '作者',
  impact_factor: 'IF',
  pdf_file: '文件',
};
