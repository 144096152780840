// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import {
  defaultContext,
  QueryClient,
  QueryClientProvider
} from '/root/workspace/mediflow-web_dev/node_modules/.pnpm/@tanstack+react-query@4.36.1/node_modules/@tanstack/react-query';

const reactQueryConfig = {};
const client = new QueryClient(reactQueryConfig.queryClient || {});
export function rootContainer(container) {
  return (
    <QueryClientProvider
      client={client}
      context={defaultContext}
    >
      {container}
      
    </QueryClientProvider>
  );
}
