import type { GenerateNestedKeys } from './typings';

export function createLocaleMessages<T extends Record<string, any>>(
  messages: T,
  prefix = '',
) {
  const localeMessages = {} as { [key in GenerateNestedKeys<T>]: string };
  const keys = Object.keys(messages);
  for (const key of keys) {
    const value = messages[key];
    if (typeof value === 'string') {
      localeMessages[`${prefix}${key}` as keyof typeof localeMessages] = value;
    } else if (typeof value === 'object') {
      Object.assign(
        localeMessages,
        createLocaleMessages(value, `${prefix + key}.`),
      );
    }
  }
  return localeMessages;
}
