import { ReactComponent as SuccessIcon } from '@/assets/status/success.svg';
import { ReactComponent as WaitIcon } from '@/assets/status/wait.svg';
import { ReactComponent as WarnIcon } from '@/assets/status/warn.svg';
import React from 'react';

export enum StatusEnum {
  Wait = 'wait',
  Success = 'success',
  Warn = 'warn',
}

export type StatusType = 'wait' | 'success' | 'warn';

export type StatusProps = {
  type?: StatusType;
} & React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  };

const StatusIcon: React.FC<StatusProps> = (props) => {
  const { type, ...rest } = props;
  switch (type) {
    case StatusEnum.Success:
      return <SuccessIcon {...rest} />;
    case StatusEnum.Wait:
      return <WaitIcon {...rest} />;
    case StatusEnum.Warn:
      return <WarnIcon {...rest} />;
    default:
      return null;
  }
};

export default StatusIcon;
