import { getApiEnv } from './getApiEnv';
import { ApiEnv } from './typings';

export const API_ENV = getApiEnv();

export const COM_BASE_URL = (
  {
    // 本地开发通过代理请求 https://dev-api-mediflow.nullht.com
    // @see .umirc proxy
    local: '/api',
    // dev: 'https://dev-api-mediflow.nullht.com',
    // prod: 'https://api-mediflow.nullht.com',
    dev: 'https://dev-api.medknows.com',
    prod: 'https://api.medknows.com',
  } as Record<ApiEnv, string>
)[API_ENV];

export const BASE_URL = location.host.includes('mediflow.ai')
  ? location.origin
  : COM_BASE_URL;

/** 测试knows token */
export const TEST_KNOWS_TOKEN =
  'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJqdGkiOiI1ZDA5YTRkZGIzNzU0NjEwYjM3ODNlOThmNzAzZGRkMyIsImlkIjoiNzI0YjE0Y2JiMTY2OThkMGVmYWZlMjk4ZjU3ZjA5OTMiLCJpc3MiOiJrbm93cyIsInN1YiI6IndlY2hhdCIsImlhdCI6MTY4ODU1NDMyNCwiZXhwIjo2MDY0ODg1NTQzMjR9.eZfI-pOpB-ngaiEcFn8mNbaUuFz549mVg-JCY0RUwxo';
