import { MessageId } from '@/locales/typings';
import { useIntl } from '@umijs/max';
import { useCallback } from 'react';
import {
  GenerateMessageValues,
  MessageDescriptor,
  MessageIdOrDescriptor,
} from './typings';

function resolveMessageDescriptor(
  idOrDescriptor: MessageIdOrDescriptor<MessageId>,
) {
  return typeof idOrDescriptor === 'string'
    ? { id: idOrDescriptor }
    : idOrDescriptor;
}

type GlobalMessageValues = GenerateMessageValues<string>;

interface GlobalFormatMessageFn {
  (id: MessageId, values?: GlobalMessageValues): string;
  (
    descriptor: MessageDescriptor<MessageId>,
    values?: GlobalMessageValues,
  ): string;
}

export function useGlobalFormatMessageFn() {
  const { formatMessage } = useIntl();
  return useCallback<GlobalFormatMessageFn>(
    (idOrDescriptor, values) =>
      formatMessage(
        resolveMessageDescriptor(idOrDescriptor),
        values as Record<string, any>,
      ),
    [],
  );
}

export function useGlobalFormattedMessage(
  id: MessageId,
  values?: GlobalMessageValues,
): string;

export function useGlobalFormattedMessage(
  descriptor: MessageDescriptor<MessageId>,
  values?: GlobalMessageValues,
): string;

export function useGlobalFormattedMessage(
  idOrDescriptor: any,
  values?: GlobalMessageValues,
) {
  const formatFn = useGlobalFormatMessageFn();
  return formatFn(idOrDescriptor, values);
}
