import { genAllPath } from '@/utils/jump';
import { produce } from 'immer';
import { uniqWith } from 'lodash';
import { v4 as uuid } from 'uuid';
import { combine } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';

/** menu类型, 文献管理页面或knows页面， 默认文献管理页面 */
export type MenuType = 'doc' | 'knows' | 'interpretation';

export type ProjectValue = {
  /** 页面路径，优先取该值 */
  path: string;
  /** 路径id */
  id: string;
  title: string;
  parentId?: string | null;
  searchQuery: string;
  type?: MenuType;
};

export type Projects = ProjectValue[];

export const genInitValue = (
  data: Omit<Partial<ProjectValue>, 'id'> & {
    id: string;
  },
) => {
  return {
    menuKey: uuid(),
    title: data.title || '',
    type: data.type || ('doc' as ProjectValue['type']),
    level: data.parentId ? 2 : 1,
    path: genAllPath({
      type: data?.type || 'doc',
      id: data.id,
      parentId: data.parentId,
      searchQuery: data.searchQuery,
    }),
    ...data,
  } as ProjectValue;
};

/** 创建的不同新项目信息 */
const newProjectsCreator = combine(
  {
    value: [] as Projects,
  },
  function (set, get) {
    return {
      save(data: Projects | ProjectValue) {
        set({
          value: get().value.concat(data),
        });
      },
      findProject(id: string) {
        return get().value.find((project) => {
          return project.id === id;
        });
      },
      createProject(
        data: Omit<Partial<ProjectValue>, 'id'> & {
          id: string;
        },
      ) {
        const project = genInitValue(data);
        set({
          value: uniqWith(get().value.concat(project), (a, b) => a.id === b.id),
        });
        return project;
      },
      deleteProject(menuKeyOrId: string | string[]) {
        set({
          value: get().value.filter((project) => {
            return project.id !== menuKeyOrId;
          }),
        });
      },
      deleteParentAndSub(parentId: string) {
        set({
          value: get().value.filter((project) => {
            return project.id !== parentId && project.parentId !== parentId;
          }),
        });
      },
      /** 更改当前项目 */
      updateProject(key: string, project: Partial<ProjectValue>) {
        set({
          value: produce(get().value, (draft) => {
            draft.forEach((item, index) => {
              if (item.id === key) {
                draft[index] = Object.assign({}, draft[index], project);
              }
            });
          }),
        });
      },
    };
  },
);

export const useNewProjectsStore = createWithEqualityFn(newProjectsCreator);
