import { FileExcelIcon } from './FileExcelIcon'
import { FilePdfIcon } from './FilePdfIcon'
import React from 'react';

export enum FileEnum {
  PDF = 'pdf',
  Excel = 'excel',
}


export type FileType = 'pdf' | 'excel'

export type FileIconProps = {
  type?: FileType;
} & React.SVGProps<SVGSVGElement> & {
    title?: string | undefined;
  };
export const FileIcon: React.FC<FileIconProps> = (props) => {
  const { type, ...rest } = props;
  switch (type) {
    case FileEnum.PDF:
      return <FilePdfIcon width={26} height={26} {...rest} />;
    case FileEnum.Excel:
      return <FileExcelIcon  {...rest} />;
    default:
      return null;
  }
};
