import { getUserPayCards } from '@/services/user';
import { useEffect, useState } from 'react';

type Card = {
  type: 'PRO' | 'BASIC';
  buttonText: string;
  recommended: boolean;
  disabled: boolean;
  badge: string;
  price: string;
  originPrice: string;
  id: string;
  desc: string;
};

type PermissionItem = {
  type: 'REFUSE' | 'NEW' | 'ENABLE' | 'UPDATE';
  tab: 'DEFAULT' | 'AI_READ' | 'AI_SEARCH' | 'AI_FILTER';
  style: Record<string, any>;
  content: {
    text: string;
    style: Record<string, any>;
  }[];
};

type PermissionGroup = {
  title: string;
  contents: PermissionItem[];
};

export type Version = 'FREE' | 'PERSONAL' | 'PROFESSIONAL' | 'ULTIMATE';

export type PayConfig = {
  name: string;
  label: string;
  description: string;
  type: Version;
  cards: Card[];
  config: PermissionGroup[];
};

export const VersionSort = ['FREE', 'PERSONAL', 'PROFESSIONAL', 'ULTIMATE'];

const usePayConfig = () => {
  const [config, setConfig] = useState<PayConfig[]>([]);
  const [fetchLoading, setFetchLoading] = useState(false);

  const fetchPayConfig = async () => {
    try {
      setFetchLoading(true);
      const res = await getUserPayCards();
      setFetchLoading(false);
      if (res.code === '00000') {
        const cs = res.data?.map((conf) => {
          const parsedConfig = JSON.parse(conf.config);
          return {
            ...conf,
            config: parsedConfig.permissions,
            description: parsedConfig.description,
            cards:
              conf.cards?.map((item) => ({
                ...item,
                buttonText: item.button_label,
                badge: item.badge,
                price: item.unit_price,
                id: item.product_id,
                type: item.type,
                originPrice: item.original_price,
                recommended: item.display_style === 'RECOMMENDED',
                desc:
                  +item.unit_price < +item.original_price
                    ? `¥${item.original_price}${item.badge}`
                    : `大约¥${item.daily_price}/天`,
                disabled: !item.open_purchase,
              })) || [],
          };
        });
        setConfig(cs);
      }
    } catch (err) {
      setFetchLoading(false);
      console.log('err');
    }
  };

  useEffect(() => {
    fetchPayConfig();
  }, []);

  return {
    config,
    fetchLoading,
  };
};

export default usePayConfig;
