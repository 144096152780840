import { styled } from '@umijs/max';
import { WaitIcon } from './WaitIcon';

export const WaitingIcon = styled(WaitIcon)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0 {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
