import { basicColumnTitleZhCN } from './zh-CN';

export const basicColumnTitleEnUS: typeof basicColumnTitleZhCN = {
  title: 'Title',
  abstract: 'Abstract',
  fulltext: 'Full Text',
  publish_date: 'Publish Date',
  journal: 'Journal',
  authors: 'Author',
  impact_factor: 'IF',
  pdf_file: 'Pdf',
};
