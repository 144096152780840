import { ChahaoIcon, DuigouIcon } from '@/components/Icon';
import { useUserStore } from '@/store';
import { formatMessage } from '@umijs/max';
import { Modal, Spin } from 'antd';
import classNames from 'classnames';
import styles from './index.less';
import PayCode from './qrcode.png';
import usePayConfig from './usePayConfig';
const ItemIcon = ({
  name,
  size,
  color,
}: {
  name: 'UPDATE' | 'NEW' | 'REFUSE' | 'ENABLE';
  size: number;
  color: string;
}) => {
  const Component =
    name === 'UPDATE'
      ? DuigouIcon
      : name === 'NEW'
      ? DuigouIcon
      : name === 'REFUSE'
      ? ChahaoIcon
      : DuigouIcon;
  return <Component style={{ color, height: size, width: size }} />;
};

const PayConfig = () => {
  // const intl = useIntl();
  const user = useUserStore();
  const { config, fetchLoading } = usePayConfig();
  return (
    <div>
      <div className={styles.permissionTitle}>
        {formatMessage({
          id:
            user.value?.level === 'PRO'
              ? 'userInfo.permission.renewal'
              : 'userInfo.permission.upgrade',
        })}
      </div>

      <div className={styles.permissionBWrap}>
        {fetchLoading && (
          <div
            className="flex"
            style={{
              margin: '40px 0',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            <Spin />
          </div>
        )}
        {config
          .filter((c) => c.type !== 'CUSTOMER_SERVICE')
          .map((level) => (
            <div
              key={level.name}
              className={classNames(styles.swiperItem, 'swiperItem')}
            >
              <div className={classNames(styles.inner)}>
                <div className={classNames(styles.name, styles[level.type])}>
                  <span className={styles.text}>{level.name}</span>
                </div>

                <div className={styles.contentW}>
                  <div style={{ padding: '16px 0' }}>
                    <div className={styles.groupTitle}>{level.description}</div>
                    {level.config.map((per) => (
                      <div className={styles.subContent} key={per.title}>
                        <div className={styles.subTitle}>{per.title}</div>

                        {per.contents?.map((cont, idx) => (
                          <div key={idx}>
                            <div className={styles.subItem}>
                              <ItemIcon
                                name={cont.type}
                                size={
                                  cont.type !== 'UPDATE' &&
                                  cont.type !== 'REFUSE'
                                    ? 22
                                    : 20
                                }
                                color={
                                  cont.type === 'REFUSE' ? '#444' : '#3478F6'
                                }
                              />

                              <span style={{ flex: 1 }}>
                                {cont.content.map((t) => (
                                  <span key={t.text} style={t.style}>
                                    {t.text}
                                  </span>
                                ))}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      {/* <div className={styles.permissionWrap}>
          <div className={classNames(styles.card, styles.free)}>
            <div className={classNames(styles.label)}>
              {intl.formatMessage({ id: 'userInfo.level.free' })}
            </div>

            <div className={classNames(styles.list)}>
              {freePermission.map((group) => (
                <>
                  <div className={styles.group}>{group.groupName}</div>
                  <>
                    {group.child.map((child) => (
                      <div
                        className={classNames(styles.permissionItem)}
                        key={child}
                      >
                        <FreeCheckIcon style={{ height: 24, width: 24 }} />
                        <div className={classNames(styles.text)}>{child}</div>
                      </div>
                    ))}
                  </>
                </>
              ))}
            </div>
          </div>
          <div className={classNames(styles.card, styles.pro)}>
            <div className={classNames(styles.label, styles.pro)}>
              {intl.formatMessage({ id: 'userInfo.level.pro' })}
            </div>

            <div className="flex gap-4">
              {proPermission.map((group) => (
                <div
                  className={classNames(styles.list)}
                  style={{ flex: 1 }}
                  key={group.groupName}
                >
                  <div className={classNames(styles.group, styles.pro)}>
                    {group.groupName}
                  </div>
                  <>
                    {group.child.map((child) => (
                      <div
                        className={classNames(styles.permissionItem)}
                        key={child}
                      >
                        <ProCheckIcon style={{ height: 24, width: 24 }} />
                        <div className={classNames(styles.text, styles.pro)}>
                          {child}
                        </div>
                      </div>
                    ))}
                  </>
                </div>
              ))}
            </div>
          </div>
        </div> */}
      <div className={styles.pay}>
        <img src={PayCode} className={styles.payCode} />
        <div className={styles.payText}>
          {formatMessage({ id: 'userInfo.payment.payCodeText' })}
        </div>
      </div>
    </div>
  );
};

export default PayConfig;

export const renderPayConfigModal = () => {
  Modal.warn({
    width: 'fit-content',
    maskClosable: true,
    closable: true,
    title: '',
    icon: null,
    footer: null,
    className: styles.modalWrap,
    content: (
      <div style={{ width: 1200 }}>
        <PayConfig />
      </div>
    ),
  });
};
