import { ReactComponent as WaitIcon } from '@/assets/status/wait2.svg';
import { styled } from '@umijs/max';

export const WaitingIcon2 = styled(WaitIcon)`
  animation: spin 1s linear infinite;
  @keyframes spin {
    0 {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
