// 运行时配置

import { SLS_CLIENT } from '@aliyun-sls/web-browser';
import { AxiosError, RequestConfig, getLocale } from '@umijs/max';
import { ConfigProvider, message } from 'antd';
import JSONBigInt from 'json-bigint';
import { Toaster } from 'react-hot-toast';
import { useComponentStore, useUserStore } from './store';
import { getEmailLoginMode, jumpLandingPage } from './utils/jump';
import { API_ENV, BASE_URL, ResponseWrap } from './utils/request';

const SLS_ENV = API_ENV; // process.env.NODE_ENV === 'development' ? 'dev' : 'prod';
SLS_CLIENT.init({
  host: 'cn-shanghai.log.aliyuncs.com',
  project: 'log-service-project-knows-group-cn-shanghai',
  logstore: `medknows-web-${SLS_ENV}-raw`,
  workspace: `medknows-web-${SLS_ENV}`,
  env: SLS_ENV,
  service: 'web',
  enableRuntimeError: true,
  enableResourcePerf: true,
  enableRequest: true,
  enablePerf: true,
  enableTrace: true,
});

const JSONBigIntStringAsString = JSONBigInt({ storeAsString: true });

message.config({
  maxCount: 2,
});

function isJsonString(str: any): str is string {
  if (typeof str !== 'string') {
    return false;
  }
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}
const ignore = ['/', '/welcome', '/welcome/en'];

export const request: RequestConfig = {
  baseURL: BASE_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
  transformResponse: (resp) => {
    if (!isJsonString(resp)) {
      return resp;
    }
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt
    // https://stackoverflow.com/questions/43787712/axios-how-to-deal-with-big-integers
    // replace big int to string
    return JSONBigIntStringAsString.parse(resp);
  },
  requestInterceptors: [
    (reqConfig) => {
      const token = localStorage.getItem('token');
      return {
        ...reqConfig,
        headers: {
          Authorization: `Bearer ${token}`,
          ...reqConfig.headers,
          'Accept-Language': getLocale(),
        },
      };
    },
  ],
  responseInterceptors: [
    (resp) => {
      if (resp && resp.status === 200 && resp?.data?.code === 20245) {
        // 没有权限次数
        message.error(resp.data.msg);
      }
      return resp;
    },
  ],

  errorConfig: {
    errorHandler: (err) => {
      const error = err as AxiosError<ResponseWrap<any>>;
      if (error.response?.status !== 200 && error.response?.status !== 403) {
        if (ignore.includes(location.pathname)) {
          jumpLandingPage();
          return;
        }
      }
      if (error.response?.status === 401) {
        // 忽略knows的未登录，防止未绑手机号弹出登录框
        if (error.response?.data.code === 'A0220') {
          return;
        }
        const isOpened = !!useComponentStore.getState().state?.LoginModal?.open;
        if (isOpened) return;
        useUserStore.getState().clearUser();

        useComponentStore
          .getState()
          .onResetOpen('LoginModal', { emailMode: getEmailLoginMode() });
      }
      if (error.response?.status === 403) {
        if (error.response?.data.code === 'A0239') {
          message.error(error.response?.data.msg);
        }
        if (error.response?.data.code === 66255) {
          // 绑定手机号
          const isOpened =
            !!useComponentStore.getState().state?.BindPhoneModal?.open;
          if (isOpened) return;
          useComponentStore.getState().onResetOpen('BindPhoneModal', {
            keyboard: false,
            closable: false,
            maskClosable: false,
          });
          if (location.pathname === '/') {
            window.location.replace('/home');
          }
        }
      }
    },
  },
  // TODO: responseInterceptors
};

export const rootContainer = (container: JSX.Element) => {
  return (
    <ConfigProvider
      prefixCls="mediflow"
      theme={{
        token: {
          colorPrimary: '#165dff',
        },
        components: {
          Button: {
            borderRadius: 5,
            controlHeight: 40,
          },
          Popover: {
            borderRadiusLG: 8,
            boxShadowSecondary:
              '0px 24px 48px -12px rgba(11, 14, 28, 0.12), 0px 0px 24px 0px rgba(11, 14, 28, 0.08)',
          },
          Modal: {
            borderRadiusLG: 20,
          },
          Input: {
            boxShadow: 'unset',
          },
          Tooltip: {
            colorBgSpotlight: 'rgba(0, 0, 0, 0.80)',
            paddingSM: 8,
            paddingXS: 10,
          },
        },
      }}
    >
      {container}
      <Toaster position="bottom-right" />
    </ConfigProvider>
  );
};
