import { renderPayConfigModal } from '@/pages/Payment/PayConfig';
import { AxiosError } from '@umijs/max';
import { message } from 'antd';
import { isFunction } from 'lodash';
import {
  ResponseError,
  ResponseErrorWrap,
  ResponseOptions,
  ResponseWrap,
} from './typings';

export { request } from '@umijs/max';

function isResponseWrap<T>(response: any): response is ResponseWrap<T> {
  return 'code' in response && 'msg' in response;
}

export function defaultShouldThrow<T>(response: ResponseWrap<T>) {
  return response.code !== 200;
}

export function defaultOnThrow(error: any) {
  let errorMsg: string | null = null;
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  if (isAxiosResponseError(error)) {
    errorMsg = (error.response as any).data.msg || error.message;
  } else if (isResponseWrap(error)) {
    errorMsg = error.msg;
  }
  if (errorMsg) {
    message.error(errorMsg);
  }
}

export async function throwOnError<T>(
  wrapper: Promise<ResponseWrap<T>>,
  options?: {
    shouldThrow?: (response: ResponseWrap<T>) => boolean;
    onThrow?: (error: any) => void;
  },
) {
  const { shouldThrow = defaultShouldThrow, onThrow = defaultOnThrow } =
    options || {};

  const response = await wrapper;
  if (shouldThrow(response)) {
    onThrow(response);
    throw response;
  }
  return response.data;
}

export const unwrapResponse = async <T>(
  responsePromise: Promise<ResponseWrap<T>>,
  options?: ResponseOptions<T>,
) => {
  try {
    const response = await responsePromise;
    if (response.code === 200) {
      !isFunction(options) && options?.success?.(response);
      return response.data;
    }

    if (response.code === 20243) {
      renderPayConfigModal();
      return;
    }
    if (isFunction(options)) {
      options?.(response);
    } else {
      if (!options?.fail) {
        message.error(response.msg);
      }
      options?.fail?.(response);
    }
  } catch (err) {
    const response = err.response;
    if (response?.status && typeof response.status === 'number') {
      const data = response.data;
      if (isFunction(options)) {
        options?.(data);
      } else {
        if (!options?.fail) {
          message.error(data.msg);
        }
        options?.fail?.(data);
      }
      throw new Error(data.msg);
    } else {
      throw new Error(err?.reason || 'error');
    }
  }
};

/** 期望响应，error状态不会报错 */
export const expectResponse = async <T>(
  responsePromise: Promise<ResponseWrap<T>>,
  options?: ResponseOptions<T>,
) => {
  try {
    const res = await unwrapResponse(responsePromise, options);
    if (!res) {
      console.log(res);
      return Promise.reject('empty response');
    }
    return res;
  } catch (e) {
    return Promise.reject(e);
  }
};

export const isAxiosResponseError = (
  err: ResponseError,
): err is AxiosError<ResponseErrorWrap> => {
  return 'response' in err;
};

/** 业务状态码非200时 */
export const isBizResponseError = (
  err: ResponseError,
): err is ResponseErrorWrap => {
  return 'msg' in err;
};

/** http状态码非200或响应状态码非200时msg */
export const getResponseErrMsg = (err: ResponseError) => {
  if (!err) return '';

  if (isBizResponseError(err)) return err.msg;
  return err.response?.data.msg;
};

export * from './constants';
export * from './typings';
