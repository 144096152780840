/** 用户登录信息 */
import { SMSLoginFormField } from '@/components/LoginModal/SMSLoginForm';
import {
  bindPhone,
  checkUserCredits,
  findUser,
  loginServer,
  logout,
  updateAvatar,
  updateUser,
} from '@/services/user';
import {
  BindPhoneParams,
  CommonLoginParamsBySMS,
  FindUserResponse,
  KnowsUserInfo,
  SMSLoginResponse,
  UpdateUserParams,
} from '@/services/user/typings';
import { tryCatch } from '@/utils';
import { jumpLandingPage } from '@/utils/jump';
import { ResponseWrap, unwrapResponse } from '@/utils/request';
import { SLS_CLIENT } from '@aliyun-sls/web-browser';
import { message } from 'antd';
import { combine } from 'zustand/middleware';
import { createWithEqualityFn } from 'zustand/traditional';
import { useNewProjectsStore } from '../newProjects';

export type User = Pick<
  SMSLoginResponse,
  'id' | 'nickname' | 'phone' | 'state'
> & {
  avatar?: string | null;
  // weChat?: string | null;
  email?: string | null;
  credits?: number | null;
  locale?: string | null;
  interfaceConfig?: KnowsUserInfo['tenant']['tenant_config']['web'];
} & Pick<
    FindUserResponse,
    'approval_status' | 'permission' | 'level' | 'valid_date'
  >;

const userCreator = combine(
  {
    value: null as User | null,
  },
  function (set, get) {
    function isLoggedIn() {
      return !!get().value;
    }

    function hasPhoneNumber() {
      return isLoggedIn() && !!get().value?.phone;
    }
    return {
      isLoggedIn,
      hasPhoneNumber,
      save(value: Partial<User>) {
        set({
          value: Object.assign({}, get().value, value),
        });
      },
      clearUser() {
        localStorage.removeItem('token');
        /** 清空新开的menu */
        useNewProjectsStore.setState({
          value: [],
        });
        set({
          value: null,
        });
      },
      async checkCreditLeft() {
        const { save } = this || get();
        tryCatch(async () => {
          const left = await checkUserCredits();
          if (typeof left === 'number') {
            save({ credits: left });
          }
        });
      },
      async findUser(options?: {
        success?: (user: FindUserResponse) => void;
        fail?: (result?: ResponseWrap<FindUserResponse>) => void;
      }) {
        const { save } = this || get();
        try {
          const res = await findUser();
          if (res.data) {
            const userInfo = res.data;
            save({
              id: userInfo.user_id!,
              nickname:
                userInfo.nick_name ||
                userInfo.mobile ||
                userInfo?.email ||
                'DefaultName',
              email: userInfo.email,
              phone: userInfo.mobile,
              avatar: userInfo.avatar,
              state: userInfo?.state,
              // weChat: userInfo?.wechat_name,
              level: userInfo?.level,
              permission: userInfo?.permission,
              valid_date: userInfo?.valid_date,
              interfaceConfig: userInfo?.tenant?.tenant_config?.web,
            });

            if (userInfo.user_id) {
              SLS_CLIENT.setOptions({
                uid: userInfo.user_id, // 更新用户ID
              });
            }

            options?.success?.(res.data);
            return;
          }
          options?.fail?.(res);
        } catch (e) {
          options?.fail?.();
        }
      },
      async phoneLogin(form: SMSLoginFormField) {
        const { findUser } = this || get();

        // LOGIN 手机号登录
        await unwrapResponse(
          loginServer({
            type: 'SMS',
            number: form.phone,
            account_captcha: form.code,
          } as CommonLoginParamsBySMS),
          {
            fail: () => {},
            success: () => {
              findUser();
            },
          },
        );
      },
      updateUser(data: UpdateUserParams) {
        tryCatch(async () => {
          const { save } = this || get();
          await unwrapResponse(updateUser(data));
          save({
            id: data.id,
            nickname: data.name,
          });
        });
      },
      async bindPhone(data: BindPhoneParams) {
        const { save } = this || get();
        const res = await bindPhone(data);
        if (res.code === 200) {
          save({
            phone: data.phone,
          });
        }
        return res;
      },
      async updateAvatar(data: { file_key: string }, errorInfo: string) {
        const { findUser } = this || get();
        try {
          await unwrapResponse(updateAvatar(data), (res) => {
            message.error(res.msg);
          });
          findUser();
        } catch (e) {
          message.error(errorInfo);
        }
      },
      logout() {
        const { clearUser } = this || get();
        tryCatch(async () => {
          await unwrapResponse(logout());
          clearUser();
          jumpLandingPage();
        });
      },
    };
  },
);

export const useUserStore = createWithEqualityFn(userCreator);
